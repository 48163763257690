import axios from 'axios';
import {clarify} from "../utils/clear";

class BannerApi {
    constructor() {
        this.endpoint = process.env.VUE_APP_BASE_API;
    }


    list(bannerCategory, body) {
        const params = new URLSearchParams(clarify(body));
        return axios.get(`${this.endpoint}/banner/list/${bannerCategory}`, {params}).then(response=>response.data);
    }

    get(bannerId) {
        return axios.get(`${this.endpoint}/banner/get/${bannerId}`, {}).then(response=>response.data);
    }

    save(banner) {
        return axios.post(`${this.endpoint}/banner/save`, banner).then(response=>response.data);
    }

    delete(bannerId) {
        return axios.delete(`${this.endpoint}/banner/delete/${bannerId}`, {}).then(response=>response.data);
    }

    uploadImage(f) {
        const formData = new FormData();
        formData.append('file', f);
        return axios.post(`${this.endpoint}/banner/upload/image`, formData, {headers: {'Content-Type': 'multipart/form-data'}}).then(response => response.data);
    }

    order(bannerId, newOrderNo) {
        return axios.post(`${this.endpoint}/banner/${bannerId}/order`, {newOrderNo}).then(response=>response.data);
    }

}


export default new BannerApi();
