import axios from 'axios';
import {clarify} from "@/utils/clear";

class PopupApi {
    constructor() {
        this.endpoint = process.env.VUE_APP_BASE_API;
    }


    list(popupCategory, body) {
        const params = new URLSearchParams(clarify(body));
        return axios.get(`${this.endpoint}/popup/list/${popupCategory}`, {params}).then(response=>response.data);
    }

    get(popupId) {
        return axios.get(`${this.endpoint}/popup/get/${popupId}`, {}).then(response=>response.data);
    }

    save(popup) {
        return axios.post(`${this.endpoint}/popup/save`, popup).then(response=>response.data);
    }

    delete(popupId) {
        return axios.delete(`${this.endpoint}/popup/delete/${popupId}`, {}).then(response=>response.data);
    }

    uploadImage(f) {
        const formData = new FormData();
        formData.append('file', f);
        return axios.post(`${this.endpoint}/popup/upload/image`, formData, {headers: {'Content-Type': 'multipart/form-data'}}).then(response => response.data);
    }

    getCategory() {
        return axios.get(`${this.endpoint}/popup/category`, {}).then(response=>response.data);
    }

}


export default new PopupApi();
