<template>
  <!-- id : contents : (S) -->
  <div id='contents' class="st-main" >
    <div class="popup-wrap" :style="popupStyle(data)" v-for="(data, idx) in popup" :key="data.popupId">
      <div class="pop-inner-box" >
        <div class="pop-body-box">
          <!-- 2021-01-05 add -->
          <div class="popup-close"><a href="javascript:;" class="pop-btn-cloe" @click="closePopup(data)">닫기</a></div>
          <!-- 2021-01-05 add -->
          <div v-if="data.url">
            <div @click="popupGoLink(data.url, data.urlTarget)" style="cursor: pointer;"  :style="popupContentStyle(data)" v-html="data.contents"></div>
          </div>
          <div v-if="data.url === ''">
            <div :style="popupContentStyle(data)" v-html="data.contents"></div>
          </div>
        </div>
        <div class="pop-btm-box">
          <input type="checkbox" class="pop-chk" :id="'pop-'+idx" v-model="hidePopup" :value="data.popupId"><label :for="'pop-'+idx">하루동안 보지않기</label>
          <a href="javascript:;" class="pop-btn-cloe" @click="closePopup(data)">닫기</a>
        </div>
      </div>
    </div>

    <!-- class : content-wrap : (S) -->
    <section class="content-wrap">
      <!-- class : main-wrap (S) -->
      <div class="main-wrap">
        <div class="mb-main-box">
          <div class="logo-box"><h1 class="logo"></h1></div>
          <div class="main-title-box">
            <div class="tit">전자사랑모임</div>
            <div class="txt">안녕하세요, {{this.$store.state.user.username}}님 !</div>
          </div>
          <div class="smenu-box">
            <a href="/board/566?upper=431&menu=487" class="btns"><i class="icon-notice"></i><span>공지사항</span></a>
            <a href="/board/567?upper=431&menu=489" class="btns"><i class="icon-joy"></i><span>경조사</span></a>
            <a href="/group/schedule?upper=431&menu=488" class="btns"><i class="icon-promotion"></i><span>행사게시판</span></a>
            <a href="/board/569?upper=431&menu=491" class="btns"><i class="icon-info"></i><span>정보/소통</span></a>
            <a href="/board/568?upper=431&menu=490" class="btns"><i class="icon-photo"></i><span>포토게시판</span></a>
            <a href="/my/inquiry" class="btns"><i class="icon-inquiry"></i><span>1:1 문의</span></a>
          </div>
          <div class="guide-box">
            <a href="/meeting/6?upper=425&menu=433" class="btns">
              <div class="tit">e-club 가이드</div>
              <div class="txt">신규회원을 위한 가이드</div>
            </a>
            <a href="https://jfamilymall.com/" target="_blank" title="전자사랑몰 새창으로" class="btns">
              <!--https://www.imarket.co.kr/srpp 240401 요청 으로 변경-->
              <div class="tit">전자사랑몰</div>
              <div class="txt">전자사랑 회원 특판몰</div>
            </a>
          </div>
        </div>
        <!-- class : main-vi-wrap (S) -->
        <div class="main-vi-wrap">
          <div class="main-vi-box">
            <div class="swiper-button-next custom-next" @click="onClickCurrentPage(false,'')"></div>
            <div class="swiper-button-prev custom-prev" @click="onClickCurrentPage(true,'')"></div>
            <slider ref="slider" class="vi-item-box" style="width:100%" :options="options">
              <slideritem v-for="(data,i) in visualMain"
                          class="swiper-slide"
                          :key="`mainUrls-${i}`">
                <div class="img-box" :style="`width:100%;background: url(/attachment/banner/${data.bannerImage}) center top no-repeat;background-size: cover`" @click="onClickBanner(data.url)">
                </div>

                <div class="sp-page-box">
                  <div class="swiper-pagination"></div>
                </div>
                <div class="txt-box">
                  <div class="inner-box"><span class="">{{ data.title }}</span><em>{{ data.alt }}</em></div>
                </div>
              </slideritem>

            </slider>
          </div>
        </div>
        <!-- class : main-vi-wrap (E) -->
        <!-- class : main-sec-01 (S) -->
        <div class="main-sec-01">
          <div class="inner-base-box">
            <div class="main-go-box">
              <div class="col-box st1">
                <div class="tit">전자사랑모임</div>
                <div class="txt">전자를 사랑하는 사람들의 모임, <br>e-CLUB 전자사랑에 오신 것을 환영합니다.</div>
              </div>
              <div class="col-box st2">
                <a href="/meeting/6?upper=425&menu=433" class=""><span>e-club 가이드</span><br><em>바로가기</em></a>
              </div>
              <div class="col-box st3">
                <span>전자사랑몰</span>
                <a href="https://jfamilymall.com/" class="" target="_blank" title="전자사랑몰 새창으로">바로가기</a>
              </div>
            </div>
            <!-- class : main-quickbar (S) -->
            <div class="main-quickbar">
              <a href="/member/2?upper=426&menu=441" class=""><span class="">전체일정</span><em>전자사랑 전체일정 </em></a>
              <a href="/board/567?upper=431&menu=489" class=""><span class="">경조사</span><em>각종 경조사 소식 </em></a>
              <a href="/my/inquiry" class=""><span class="">1:1 문의</span><em>일대일 문의하기 </em></a>
              <a href="/my/meetList" class=""><span class="">모임신청내역</span><em>신청한 모임 내역 확인 </em></a>
            </div>
            <!-- class : main-quickbar (E) -->
          </div>
        </div>
        <!-- class : main-sec-01 (E) -->
        <!-- class : main-sec-notice (S) -->
        <div class="main-sec-notice">
          <div class="inner-base-box">
            <div class="tit-box">
              <div class="tit">공지사항</div>
              <div class="btn-more"><router-link to="/board/566?upper=431&menu=487" class="dg-fnt-mont">VIEW MORE</router-link></div>
            </div>
            <ul>
              <li v-for="article in notice" :key="article.articleId">
                <router-link :to="`/board/566?upper=431&menu=487&c=detail&at=${article.articleId}&fr=main`" class="">
                  <div class="tit-s">{{ article.title }}</div>
                  <div class="txt-s" v-html="article.contents.replace(/<div><br><\/div>/g, '')" v-if="!article.mobile"></div>
                  <div class="txt-s" v-html="article.contents.replace(/(?:\r\n|\r|\n)/g, '<br />')" v-else></div>
                  <div class="dg-fnt-mont date">{{ formatDate(article.createdAt) }} </div>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <!-- class : main-sec-notice (E) -->

        <!-- class : main-sec-photo (S) -->
        <div class="main-sec-photo">
          <div class="inner-base-box">
            <div class="tit-box">
              <div class="tit">포토갤러리</div>
              <div class="btn-more"><router-link to="/board/568?upper=431&menu=490" class="dg-fnt-mont">VIEW MORE</router-link></div>
            </div>
            <ul>
              <li v-for="photo in photos" :key="photo.articleId">
                <a :href="`/board/568?upper=431&menu=490&c=detail&at=${photo.articleId}&fr=main`" class="">
                  <div class="img-s">
<!--                    <img :src="`/attachment/board/568/article/thumb_${photo.thumbnail}`" width="280" height="210"/>-->
<!--                    <v-img :src="photo.thumbnail ? photo.thumbnail.indexOf('/') === 0 ? photo.thumbnail : `/attachment/board/568/article/thumb_${photo.thumbnail}` : '/img/photo_default.83807fa8.jpg'"-->
<!--                           width="280" height="210"-->
<!--                           v-on:error.once="photo.thumbnail=`/attachment/board/568/article/${photo.thumbnail}`"/>-->
<!--                    -->
                    <v-img :src="photo.mainImage.fileServerName ? `/attachment/board/${photo.boardId}/${photo.mainImage.thumbnail}` : '/photo_default.jpg'"
                           v-on:error.once="photo.mainImage.thumbnail='/photo_default.jpg'"
                           width="280" height="210" style="border-radius: 10px;" />
                  </div>
                  <div class="tit-s ellipsis">{{ photo.title }}</div>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <!-- class : main-sec-photo (E) -->
        <!-- class : main-sec-birth (S) -->
        <div class="main-sec-birth">
          <div class="inner-base-box">
            <div class="birth-box">
              <p class="tit">회원님들의 생일을 <br>축하해주세요!</p>
              <div class="main-slide-02">
                <slider ref="slider2" class="vi-item-box swiper-wrapper" :options="options2">
                  <slideritem v-for="(data,i) in memberImageUrls"  style="width: 230px"  class="swiper-slide" :key="`membImg-${i}`">
                    <div class="inner-box">
                      <img class="member-list-box" :src="getMemberPhoto(data.photoFile)" :class="{open:showMemberDetail(i)}" @click="showMemberDetailOpen(i)"
                           @error="(e) => e.target.src=require('@/assets/front/images/icon/member.jpg')"/>
                      <div class="txt-box">
                        <div class="date-box"><span class="date dg-fnt-mont">{{ formatBirth(data.birthDate) }}</span> <em>{{ data.lunarBirth?'음력':'양력' }}</em></div>
                        <span class="name">
                          <div class="send-msg-menu-box" :class="{open:showMemberDetail(i)}" @click="showMemberDetailOpen(i)"><!-- open 클래스 추가 시 버튼 노출 -->
                              <a href="javascript:;" class="btnSendMenu">{{ data.name }} 님</a>
                              <div class="btn-list-box" style="margin-left: -25px">
                                  <a href="javascript:;" class="btnPeopleDtlOver" @click="onClickUser(data.userId, 'detail')">회원 정보</a>
                                  <a href="javascript:;" class="btnMemoSendOver" @click="onClickUser(data.userId, 'message')">쪽지보내기</a>
                              </div>
                          </div>
                        </span>
                      </div>
                    </div>
                  </slideritem>
                </slider>
                <!-- Add Navigation -->
                <div class="swiper-button-prev" @click="onClickCurrentPage(true,'2')"></div>
                <div class="swiper-button-next" @click="onClickCurrentPage(false,'2')"></div>
              </div>
            </div>
            <div class="main-banner-box main-slide-03">
              <slider ref="slider3" class="vi-item-box" style="width:100%" :options="options3">
                <slideritem v-for="(banner,i) in banners" class="swiper-slide" :key="`mainUrls-${i}`">
                  <img v-if="banner.activated" :src="`/attachment/banner/${banner.bannerImage}`" @click="onClickBanner(banner.url)"/>
                </slideritem>
              </slider>
              <!-- Add Navigation -->
              <div class="swiper-button-prev" @click="onClickCurrentPage(true,'3')"></div>
              <div class="swiper-button-next" @click="onClickCurrentPage(false,'3')"></div>
            </div>
          </div>
        </div>
        <!-- class : main-sec-birth (E) -->

      </div>
      <!-- class : main-wrap (E) -->

      <CommonPopup v-if="memberList" :tap="popupTab" :isOpen="isOpen" :userId="userId" @click:bgDimClose="popupMemberList"></CommonPopup>

    </section>
    <!-- class : content-wrap : (E) -->
  </div>
  <!-- id : contents : (E) -->
</template>
<style>
.swiper-container-horizontal>.slider-pagination-bullets{
  bottom: 85px !important;
}
.swiper-container-horizontal>*>.slider-pagination-bullet{
  background:rgba(255, 255, 255) !important;
  opacity:.6 !important;
  margin: 0 2px !important;
}
.swiper-container-horizontal>*>.slider-pagination-bullet-active{
  opacity:1 !important;
  border-radius: 4px !important;
  width: 19px !important;
}
.slider-item{
  text-align: none !important;
  font-size: 16px !important;
  text-align: unset !important;
  justify-content: unset !important;
}
.slider-container{
  overflow: unset !important;
}
</style>
<script>
import {slider, slideritem} from 'vue-concise-slider'
import articleApi from "@/api/ArticleApi";
import moment from "moment";
import BannerApi from "@/api/BannerApi";
import UserApi from "@/api/UserApi";
import CommonPopup from "@/views/include/common_popoup";
import PopupApi from "@/api/PopupApi";

export default {
  name: "main",
  components: {
    slider,
    slideritem,
    CommonPopup,
  },
  data() {
  return {
    photos:[],
    banners:[],
    hidePopup: [],

    memberList: false,
    popupTab: 'detail',
    userId: null,
    memberOpen:false,
    rowIndex:-1,
    options:{
      pagination: true,
      thresholdDistance: 100, // Sliding distance threshold
      thresholdTime: 300, // Sliding time threshold decision
      grabCursor: true, // Scratch style
      speed: 300, // Sliding speed
      loop:true,
      autoplay:'5000',
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    },
    options2:{
      currentPage: 0,
      pagination: false,
      loopedSlides: 3,
      thresholdDistance: 100, // Sliding distance threshold
      thresholdTime: 300, // Sliding time threshold decision
      grabCursor: true, // Scratch style
      speed: 300, // Sliding speed
      loop: false
    },
    option2index:0,
    options3:{
      pagination: null,
      thresholdDistance: 100, // Sliding distance threshold
      thresholdTime: 300, // Sliding time threshold decision
      grabCursor: true, // Scratch style
      speed: 300, // Sliding speed
      loop:true,
      autoplay:'3000'
    },
    visualMain:[],
    memberImageUrls:[],

    notice:[],
    popup:[],
    }
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    isOpen(e) {
      if (!e) {
        this.memberList = false
      }
    }
  },
  created() {
    this.getPopup();
    this.getContents();
    this.getBanner();

  },
  methods: {
    formatBirth(val){
      return moment(val).format('MM.DD');
    },
    getContents(){
      let param = {
        size : 4
      }
      articleApi.list(566, param).then(data => {
        this.notice = data.content;
      });

      articleApi.list(568, param).then(data => {
        this.photos = data.content;
      });

      UserApi.birthUsers().then(data=>{
        this.memberImageUrls = data.content;
        this.memberImageUrls.sort(function(a , b) {
          return a.birthDate.substring(5) < b.birthDate.substring(5) ? -1 : a.birthDate.substring(5) > b.birthDate.substring(5) ? 1 : 0;
        })
        // this.memberImageUrls.forEach(m => console.log(m.birthDate.substring(5)))
      })
    },
    getBanner(){
      let params = { page:1 }
      BannerApi.list('TYPE_HOME_VISUAL', params).then(data => {
        this.visualMain = data.content.filter(d=>d.activated);
      });
      BannerApi.list('TYPE_HOME_MAIN', params).then(data => {
        this.banners = data.content.filter(d=>d.activated);
      });
    },
    onClickCurrentPage(e,slideType){
      //this.rowIndex = -1
      let move = e ? 'slidePre' : 'slideNext'
      if(slideType === '2'){
        if(e){
          if(this.option2index>0) {
            this.option2index = this.option2index - 1
            this.$refs.slider2.$emit(move)
          }
        }else{
          if(this.option2index<this.memberImageUrls.length-3)
          {
            this.option2index = this.option2index+1
            this.$refs.slider2.$emit(move)
          }
        }
      }else if(slideType === '3'){
        this.$refs.slider3.$emit(move)
      }else{
        this.$refs.slider.$emit(move)
      }

    },
    onSlide(e){
      console.log('onSlide',e)
      //this.rowIndex = -1
    },

    showMemberDetail(idx){
      if(this.rowIndex === idx){
        return true;
      }
    },
    showMemberDetailOpen(idx){
      let user = this.$store.state.user.userid;
      if(user == null || user == ''){
        return false;
      }

      this.memberOpen = false;
      if(this.rowIndex == idx){
        this.memberOpen = !this.memberOpen;
        this.rowIndex = -1;
      } else {
        this.rowIndex = idx;
      }
    },
    onClickUser(userId, popStatus){
      this.memberOpen = false;
      this.userId=userId
      this.popupTab=popStatus
      this.popupMemberList(true)
    },
    popupMemberList(e){
      this.memberList = e
      this.$emit('click:bgDim', this.memberList)
    },
    formatDate(value) {
      return moment(value).format('YYYY.MM.DD');
    },
    closePopup(val){
      this.popup = this.popup.filter(d=>d!==val);

      this.hidePopup.forEach(d=> {
        if(d == val.popupId) {
          let date = new Date();
          date.setTime(date.getTime() + 1000 * 60 * 60 * 24 * 1); //1일
          document.cookie = 'cookie_displayPopup_' + d + '=false;expires=' + date.toUTCString() + ';path=/';
        }
      })
    },
    getPopup(){
      PopupApi.list("main", {page:1}).then(data=>{
        this.popup = data.content.map(this.popupFilter).filter(c=>c!==null)
      })
    },
    popupFilter(m){
      if(!m.activated) {
        return null;
      }

      if(!m.always) {
        let moment = require('moment')
        let now = moment().format('YYYY-MM-DD HH:mm:ss')

        let start = '';
        let end = '';
        if(m.startTm == null){
          start = moment(m.startDt).format('YYYY-MM-DD')
        }else{
          start = moment(m.startDt+'T'+m.startTm).format('YYYY-MM-DD HH:mm:ss')
        }

        if(m.endTm == null){
          end = moment(m.endDt).format('YYYY-MM-DD')
        }else{
          end = moment(m.endDt+'T'+m.endTm).format('YYYY-MM-DD HH:mm:ss')
        }

        if(start > now || end < now) {
          return null;
        }
      }

      let search = "cookie_displayPopup_"+m.popupId;
      let value = document.cookie.match('(^|;) ?' + search + '=([^;]*)(;|$)');
      if(value != null){
        return null;
      }

      return {
        popupId : m.popupId,
        title : m.title,
        contents : m.contents,
        positionLeft : m.positionLeft,
        positionTop : m.positionTop,
        sizeHeight : m.sizeHeight,
        sizeWidth : m.sizeWidth,
        urlTarget : m.urlTarget,
        url : m.url,
        layer:m.layer
      }
    },

    popupStyle(val){ //위치
      return 'left:'+val.positionLeft+'px;top:'+val.positionTop+'px';
    },
    popupContentStyle(val){ //크기
     return 'width:'+val.sizeWidth+'px;height:'+val.sizeHeight+'px';
    },
    onClickBanner(url){
      if(url != null && url != '') {
        window.open(url, '', '');
      }
    },
    getMemberPhoto(photoFile){
      if(photoFile==null){
        return '../../../src/assets/front/images/icon/member.jpg';
      }else{
        return `/attachment/user/photo/${photoFile}`;
      }
    },
    popupGoLink(url, target){
      if (target === '_blank') {
        window.open(url, '_blank');
      } else {
        // 다른 경우에는 현재 창에서 이동
        window.location.href = url;
      }
    }
  },
}
</script>
